import React, {useContext, useEffect} from "react";
import {LangContext} from "../languageContext";


export const EpostKvittering = () => {
    const langContext = useContext(LangContext);

    useEffect(() => {
        document.title = langContext.lang.pageTitles.emailReceipt;
    }, [langContext]);

    return(
        <div style={{margin: "2rem"}}>
            <h1>{langContext.lang.emailReceipt.message}</h1>
        </div>
    )
};
