import React, {useContext, useEffect} from "react";
import './aboutUs.css'
import {LangContext} from "../languageContext";

export const AboutUs = () => {
    const langContext = useContext(LangContext);

    useEffect(() => {
        document.title = langContext.lang.pageTitles.aboutUs;
    }, [langContext]);

    return(
        <div className="margin">
            <h1>{langContext.lang.aboutUs.header}</h1>
            <h2>{langContext.lang.aboutUs.subHeader}</h2>
            <p>{langContext.lang.aboutUs.text}</p>
        </div>
    )
};