import * as React from "react";
import KubeVekt from "../media/KubeVekt.jpg";
import SheepApp from "../media/sheep-app.jpg";

import {Card, CardGroup} from "react-bootstrap";
import {useContext, useEffect} from "react";
import {LangContext} from "../languageContext";

export const Products = () => {
    const langContext = useContext(LangContext);

    useEffect(() => {
        document.title = langContext.lang.pageTitles.products;
    }, [langContext]);

    return(
        <div className="test">
            <CardGroup>
                <Card>
                    <Card.Img variant="top" src={SheepApp} alt={langContext.lang.products.sheepBell.altText}/>
                    <Card.Body>
                        <Card.Text>
                            {langContext.lang.products.sheepBell.text}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={KubeVekt} alt={langContext.lang.products.beeHive.altText}/>
                    <Card.Body>
                        <Card.Text>
                            {langContext.lang.products.beeHive.text}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    )
};