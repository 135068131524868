import {LangContext} from "../languageContext";
import React from "react";
import "./langButton.css";
import norwayFlag from "../media/norway-flag-round.png"
import ukFlag from "../media/united-kingdom-flag-round.png"

export const LangButton = (props: {country : string}) => {
    return(
        <LangContext.Consumer>
            {({lang, toggleLang}) => (
                <button
                    className="langButton"
                    onClick={() => toggleLang(props.country)}>
                    <img className="buttonImage" src={props.country === "no" ? norwayFlag : ukFlag} alt={props.country === "no" ? lang.langButtonAltTextNorwegian : lang.langButtonAltTextEnglish }/>
                </button>
            )}
        </LangContext.Consumer>
    )
};
