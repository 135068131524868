import * as React from 'react';
import {Router, Route, BrowserRouter, HashRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import {Home} from "./pages/home";
import {NavBar} from "./components/navBar";
import {ContactScheme} from "./pages/contact";
import {Products} from "./pages/products";
import {AboutUs} from "./pages/aboutUs";
import {Footer} from "./components/footer";
import {EpostKvittering} from "./pages/epostKvittering";
import {lang, LangContext} from "./languageContext";
import {useEffect, useState} from "react";
const history =  createBrowserHistory();

const App: React.FC = () => {

    const [language, setLang] = useState(lang.EN);

    function toggleLanguage(l: string) {
//        setLang(language => (language === lang.NO ? lang.EN : lang.NO));

        if(l == "no"){
            setLang(lang.NO);
        } else if (l === "en"){
            setLang(lang.EN);
        }
    }

    useEffect(() => {
        document.documentElement.lang = language.languageCode;
    }, [language]);

    return (
        <LangContext.Provider value={{lang: language, toggleLang: toggleLanguage}}>
            <HashRouter>
                <NavBar/>
                <Route exact path="/" component={Home} />
                <Route exact path="/kontakt" component={ContactScheme} />
                <Route exact path="/kvitteringEpost" component={EpostKvittering} />
                <Route exact path="/produkt" component={Products} />
                <Route exact path="/oss" component={AboutUs} />
                <Footer/>
            </HashRouter>
        </LangContext.Provider>
    );
};

export default App;
