import React, {useContext, useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import './contact.css'
import {LangContext} from "../languageContext";

export const ContactScheme = () => {
    const langContext = useContext(LangContext);

    useEffect(() => {
        document.title = langContext.lang.pageTitles.contactScheme;
    }, [langContext]);

    return(
        <Form method="POST" action="https://www.domeneshop.no/cgi-bin/mailto.cgi" acceptCharset="ISO-8859-1">
            <h1>{langContext.lang.contact.header}</h1>
            <input type="hidden" name="_to" value="post@eggja.no"/>
            <input type="hidden" name="_from" value="feedback@eggja.no"/>
            <input type="hidden" name="_subject" value="Tilbakemelding"/>
            <input type="hidden" name="_resulturl" value="https://www.eggja.no/#/kvitteringEpost"/>


            <Form.Group>
                <Form.Label>{langContext.lang.contact.name}</Form.Label>
                <Form.Control required name="namn" type="text" placeholder={langContext.lang.contact.namePlaceholder}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>{langContext.lang.contact.email}</Form.Label>
                <Form.Control required name="epost" type="text" placeholder={langContext.lang.contact.emailPlaceholder}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>{langContext.lang.contact.message}</Form.Label>
                <Form.Control required name="melding" as="textarea" rows="7" maxLength="500"/>
            </Form.Group>



            <Button variant="warning" type="submit">
                {langContext.lang.contact.sendButton}
            </Button>
        </Form>
    )
};