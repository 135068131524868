import * as React from "react";
import { NavLink } from 'react-router-dom';
import eggja from '../media/eggja.png'
import '../pretty.css'
import './navBar.css'
import {Form, FormControl, Button, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useContext} from "react";
import {LangContext} from "../languageContext";
import { LangButton} from "./langButton";

export const NavBar = () => {
    const langContext = useContext(LangContext);

    return(
        <Navbar className="navbar-custom" expand="lg" variant="light">
            <NavLink className="navbar-brand" to="/">
                <img src={eggja} alt="logo" height="30" />
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto text-right">
                    <LangButton country="no"/>
                    <LangButton country="en"/>
                    <NavLink className="link nav-link" to="/oss">{langContext.lang.header.aboutUs}</NavLink>
                    <NavLink className="link nav-link" to="/produkt">{langContext.lang.header.product}</NavLink>
                    <NavLink className="link nav-link" to="/kontakt">{langContext.lang.header.contact}</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};