import React from "react";

const webpageName = " | Eggja";

export const lang = {
    NO: {
        languageCode: "no",
        langButtonAltTextNorwegian: "Sett språk på sida til norsk",
        langButtonAltTextEnglish: "Sett språk på sida til engelsk",
        pageTitles: {
            home: "Eggja",
            contactScheme: "Kontakt oss" + webpageName,
            emailReceipt: "Takk for førespurnad" + webpageName,
            products: "Produkt" + webpageName,
            aboutUs: "Om oss" + webpageName,
        },
        header: {
            aboutUs: "Om oss",
            product: "Produkt",
            contact: "Kontakt",
        },
        aboutUs: {
            header: "Om oss",
            subHeader: "Eggja AS",
            text: "Digitale løysingar for den moderne gardbrukar. Lokalisert på vestlandet."
        },
        contact: {
            header: "Kontaktskjema",
            name: "Namn",
            namePlaceholder: "Skriv namnet ditt her",
            emailPlaceholder: "Skriv eposten din her",
            email: "E-postadresse",
            message: "Melding",
            sendButton: "Send melding"
        },
        emailReceipt: {
            message: "Takk for at du kontakta oss! Di melding er registrert."
        },
        products: {
            beeHive: {
                text: "Bikuber med fjernavlesing av vekt.",
                altText: "Bikube med vekt."
            },
            sheepBell: {
                text: "Sporing av husdyr i utmark.",
                altText: "Mobiltelefon med saueapp",
            }
        }
    },
    EN: {
        languageCode: "en",
        langButtonAltTextNorwegian: "Set language on page to norwegian",
        langButtonAltTextEnglish: "Set language on page to english",
        pageTitles: {
            home: "Eggja",
            contactScheme: "Contact us" + webpageName,
            emailReceipt: "Thank you for your inquiry" + webpageName,
            products: "Products" + webpageName,
            aboutUs: "About us" + webpageName,
        },
        header: {
            aboutUs: "About us",
            product: "Products",
            contact: "Contact us",
        },
        aboutUs: {
            header: "About us",
            subHeader: "Eggja AS",
            text: "Digital solutions for the modern farmer. Based in western Norway."
        },
        contact: {
            header: "Contact form",
            name: "Name",
            namePlaceholder: "Your name here",
            emailPlaceholder: "Your email here",
            email: "E-mail",
            message: "Message",
            sendButton: "Send message"
        },
        emailReceipt: {
            message: "Thanks for your message! Your message is registered."
        },
        products: {
            beeHive: {
                text: "Remote monitored beehive scales.",
                altText: "Beehive with scale."
            },
            sheepBell: {
                text: "Tracking livestock in outlying fields.",
                altText: "Mobile phone with sheep app.",
            }
        }
    },
};

export const LangContext = React.createContext(
    {
    lang: lang.NO,
    toggleLang: (lang) => {},
});