import * as React from "react";
import '../pretty.css';
import background from "../media/lys-himmel-bakgrunn-smaller-v2.jpg"
import './home.css'
import {useContext, useEffect} from "react";
import {LangContext} from "../languageContext";

export const Home = () => {
    const langContext = useContext(LangContext);

    useEffect(() => {
        document.title = langContext.lang.pageTitles.home;
    }, [langContext]);

    return(
        <div className="home-wrapper">
            <img className="frontpage_image" src={background} alt=""/>
        </div>
    )
};