import {Navbar} from "react-bootstrap";
import React from "react";
import './footer.css'


export const Footer = () => {
    return(
        <Navbar className="footer" fixed="bottom" >
            <p className="footer-text">Eggja AS © 2020</p>
        </Navbar>
    )
};